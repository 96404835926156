<template>
  <v-container></v-container>
</template>

<script>
  export default {
    name: 'ItemProperty',
  }
</script>

<style scoped>

</style>
